@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');



* {
  font-size: 62.5%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}


a {
  text-decoration: none;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  background: #986B54;
}

::-webkit-scrollbar-thumb:hover {
  background:#55372B;
}

