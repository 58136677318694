@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.app {
    width: 100%;
    background-color: #FAF6F3;
}

h1, h2 {
    color: #55372B;
}

/*- - - - - - - - - - - - - - - - - - - PORTRAIT - - - - - - - - - - - - - - - - - - - */

@media (orientation: portrait) {

    .navbar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10vh;
        padding: 0 1rem;
    }

    .navbar h1 {
        font-size: 3rem;
        font-family: 'Raleway', sans-serif;
        letter-spacing: 1rem;
    }

    .nav-logo {
        display: none;
    }

    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .page {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5rem;
    }

    .page:nth-child(1) {
        height: 80vh;
    }

    .page:not(:nth-child(1)) {
        gap: 5rem;
    }

    .page h2 {
        width: 90%;
        font-size: 1.6rem;
        font-family: 'Raleway', sans-serif;
        line-height: 3rem;
        letter-spacing: 0.2rem;
        text-align: center;
    }

    .coffee-beans {
        width: 100%;
        aspect-ratio: 1/1;
        background-image: url(./images/coffee-beans.jpg);
        background-size: cover;
    }

    .gordan {
        width: 80%;
        position: absolute;
        left: 50%;
        top: 25%;
        transform: translate(-50%, 0);
    }

    .dobrodosli, .vidimo-se {
        font-family: 'Dancing Script', cursive;
        font-size: 2.5rem;
        line-height: 3rem;
        letter-spacing: 0.2rem;
        text-align: center;
    }

    .dobrodosli {
        padding-top: 5rem;
    }

    .logo-horizontal {
        width: 40%;
    }

    .siroka-slika {
        width: 100%;
        object-fit: cover;
    }

    .enterijer {
        width: 100%;
    }

    .prva-grupa-slika, .druga-grupa-slika {
        position: relative;
    }

    .prva-grupa-slika img {
        width: 55%;
    }

    .poslednja-slika {
        position: absolute;
        right: 0%;
        bottom: 0%;
        z-index: 1;
    }

    .druga-grupa-slika img {
        width: 40%;
    }

    .srednja-slika {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
    }

    .slideshow {
        width: 70%;
    }
}

/* x - x - x - x - x - x - x - x - x - x PORTRAIT x - x - x - x - x - x - x - x - x - x */



/* - - - - - - - - - - - - - - - - - - - LANDSCAPE - - - - - - - - - - - - - - - - - - - */

@media (orientation: landscape) {

    .app {
        max-width: 1200px;
        margin: 0 auto;
    }

    .navbar {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 15vh;
        padding: 0 5rem;
    }

    .navbar h1 {
        display: none;
    }

    .nav-logo {
        height: 90%;
    }

    .coffee-beans:nth-child(1) {
        width: 100%;
        height: 50vh;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3)), url(./images/coffee-beans.jpg);
        background-size: cover;
    }

    .gordan {
        width: 30%;
        position: absolute;
        left: 55%;
        top: 35%;
    }

    .page:nth-child(1) {
        height: 85vh;
        display: flex;
        flex-direction: column;
    }
    
    .logo-horizontal {
        display: none;
    }

    .dobrodosli {
        font-family: 'Dancing Script', cursive;
        font-weight: 300;
        font-size: 4rem;
        letter-spacing: 0.5rem;
        position: absolute;
        top: 90%;
        left: 5%;
    }

    .page:not(:nth-child(1)) {
        height: 200vh;
        position: relative;
    }

    .page h2 {
        font-size: 3.5rem;
        font-weight: 300;
        font-family: 'Raleway', sans-serif;
        line-height: 4rem;
        letter-spacing: 0.5rem;
        text-align: center;
    }

    .posjetite-nas {
        position: absolute;
        width: 90%;
        top: 45%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .siroka-slika {
        width: 100%;
        object-fit: cover;        
        aspect-ratio: 3/1;
    }

    .karta-pica-kafa{
        position: absolute;
        top: 5%;
    }

    .opustite-se {
        position: absolute;
        left: 5%;
        bottom: 22%;
        width: 45%;
    }

    .prva-grupa-slika, .druga-grupa-slika {
        width: 40%;
        background-image: url(./images/coffee-beans.jpg);
        background-size: cover;
        position: relative;
    }

    .prva-grupa-slika {
        aspect-ratio: 1.5/1;
        left: 55%;
        top: 60%;
    }

    .enterijer {
        position: absolute;
        top: 0%;
        left: 5%;
        width: 45%;
    }

    .uzivajte {
        position: absolute;
        width: 35%;
        right: 5%;
        top: 0%;
    }

    .osoblje {
        width: 90%;
        position: absolute;
        bottom: 15%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .osoblje-text {
        position: absolute;
        bottom: 0%;
    }

    .kolac {
        width: 60%;
        position: absolute;
        left: 5%;
        top: 5%;
    }

    .kolac-text {
        width: 60%;
        position: absolute;
        right: 5%;
        top: 30%;
    }

    .druga-grupa-slika {
        aspect-ratio: 1/1;
        left: 5%;
        top: 45%;
    }

    .oprez {
        width: 40%;
        position: absolute;
        top: 50%;
        right: 5%;
    }

    .prva-grupa-slika img {
        width: 45%;
    }

    .poslednja-slika {
        position: absolute;
        right: 0%;
        bottom: 0%;
        z-index: 1;
    }

    .druga-grupa-slika img {
        width: 40%;
    }

    .prva-slika {
        position: absolute;
        top: 10%;
    }

    .srednja-slika {
        position: absolute;
        left: 30%;
        top: 35%;
        z-index: 2;
    }

    .vidimo-se {
        font-size: 3.5rem;
        font-family: 'Dancing Script', cursive;
        font-weight: 300;
        position: absolute;
        bottom: 3%;
        left: 50%;
        transform: translate(-50%, 0);
    }

}

/* x - x - x - x - x - x - x - x - x - x LANDSCAPE x - x - x - x - x - x - x - x - x - x */

